
<template>
  <div id="Invite">
    <div class="inviteFriend">
      <div class="top">
        <vue-qr
          :logoSrc="`./logo.png`"
          :text="`http://${window.location.host}/#/Register${
            user
              ? '?invitation_code=' +
                user.invitation_code +
                (user.real_name ? '&name=' + user.real_name : '')
              : ''
          }`"
          :size="600"
          :style="{ margin: '0.5em' }"
        />
        <p>
          <span>我的邀请码: </span>
          <span ref="invite-code">
            {{ user ? user.invitation_code : "" }}
          </span>
        </p>
      </div>
      <div class="bottom">
        <a-button
          v-clipboard:copy="user ? user.invitation_code : ''"
          v-clipboard:success="copySuccess"
          v-clipboard:error="copyError"
        >
          点击复制分享<a-icon type="copy" />
        </a-button>
      </div>
    </div>
  </div>
</template>

<script>
import vueQr from "vue-qr";

export default {
  name: "Home",
  components: {
    vueQr,
  },
  beforeCreate() {
    // 获取用户信息
    this.$axios.get("profile").then((res) => {
      if (res.code === 0) {
        this.user = res.data;
      } else {
        console.log(res.code, res.message);
        this.$message.warning(res.message);
      }
    });
  },
  data() {
    return {
      user: {},
      window,
    };
  },
  methods: {
    copySuccess() {
      this.$message.success("邀请码已复制到剪切板！");
    },
    copyError() {
      this.$message.error("抱歉，复制失败！");
    },
  },
};
</script>

<style lang="less">
#Invite {
  display: flex;
  justify-content: space-around;
  flex-direction: column;
  align-content: center;
  align-items: center;

  > .inviteFriend {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: space-around;
    flex-direction: column;
    align-content: center;
    align-items: center;

    > * {
      //: 0.3rem;
      text-align: center;

      > img {
        width: 50%;
      }

      > .ant-btn {
        margin-top: 1em;
        @height: 2em;
        height: @height;
        line-height: @height;
        border-radius: 2em;
        @paddingLR: 3em;
        padding-left: @paddingLR;
        padding-right: @paddingLR;
        background-color: rgba(48, 52, 99, 1);
      }
    }
  }
}
</style>
