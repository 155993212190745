var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{attrs:{"id":"Invite"}},[_c('div',{staticClass:"inviteFriend"},[_c('div',{staticClass:"top"},[_c('vue-qr',{style:({ margin: '0.5em' }),attrs:{"logoSrc":`./logo.png`,"text":`http://${_vm.window.location.host}/#/Register${
          _vm.user
            ? '?invitation_code=' +
              _vm.user.invitation_code +
              (_vm.user.real_name ? '&name=' + _vm.user.real_name : '')
            : ''
        }`,"size":600}}),_c('p',[_c('span',[_vm._v("我的邀请码: ")]),_c('span',{ref:"invite-code"},[_vm._v(" "+_vm._s(_vm.user ? _vm.user.invitation_code : "")+" ")])])],1),_c('div',{staticClass:"bottom"},[_c('a-button',{directives:[{name:"clipboard",rawName:"v-clipboard:copy",value:(_vm.user ? _vm.user.invitation_code : ''),expression:"user ? user.invitation_code : ''",arg:"copy"},{name:"clipboard",rawName:"v-clipboard:success",value:(_vm.copySuccess),expression:"copySuccess",arg:"success"},{name:"clipboard",rawName:"v-clipboard:error",value:(_vm.copyError),expression:"copyError",arg:"error"}]},[_vm._v(" 点击复制分享"),_c('a-icon',{attrs:{"type":"copy"}})],1)],1)])])
}
var staticRenderFns = []

export { render, staticRenderFns }